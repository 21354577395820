<template>
    <div class="date-pickers">
        <el-select
                class="year select"
                v-model="currentDate.year"
                @change='judgeDay'
                placeholder="年">
            <el-option
                    v-for="(item, index) in years"
                    :key="index"
                    :label="String(item + '年')"
                    :value="item">
            </el-option>
        </el-select>
        <el-select
                class="month select"
                v-model="currentDate.month"
                @change='judgeDay'
                placeholder="月">
            <el-option
                    v-for="(item, index) in months"
                    :key="index"
                    :label="String(item).length==1?String('0'+item + '月'):String(item + '月')"
                    :value="item">
            </el-option>
        </el-select>
        <el-select
                class="month select"
                :class="{'error':hasError}"
                v-model="currentDate.day"
                placeholder="日">
            <el-option
                    v-for="(item, index) in days"
                    :key="index"
                    :label="String(item).length==1?String('0'+item + '日'):String(item + '日')"
                    :value="item">
            </el-option>
        </el-select>
    </div>
</template>

<script>
    export default {
        name: "DatePicker",
        props: {
            sourceDate: {
                type: [String, Number]
            }
        },
        data() {
            return {
                currentDate: {
                    year: "",
                    month: "",
                    day: ""
                },
                maxYear: new Date().getFullYear(),
                minYear: 1970,
                years: [],
                months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                normalMaxDays: 31,
                days: [],
                hasError: false
            };
        },
        watch: {
            sourceDate() {
                if (this.sourceDate) {
                    this.currentDate = this.timestampToTime(this.sourceDate);
                }
            },
            normalMaxDays() {
                this.getFullDays();
                if (this.currentDate.year && this.currentDate.day > this.normalMaxDays) {
                    this.currentDate.day = "";
                }
            },
            currentDate: {
                handler(newValue) {
                    this.judgeDay();
                    if (newValue.year && newValue.month && newValue.day) {
                        this.hasError = false;
                    } else {
                        this.hasError = true;
                    }
                    this.emitDate();
                },
                deep: true
            }
        },
        created() {
            this.getFullYears();
            this.getFullDays();
        },
        methods: {
            emitDate() {
                let timestamp; //暂默认传给父组件时间戳形式
                if ( this.currentDate.year && this.currentDate.month && this.currentDate.day) {
                    let month = this.currentDate.month < 10 ? ('0'+ this.currentDate.month):this.currentDate.month;
                    let day = this.currentDate.day < 10 ? ('0'+ this.currentDate.day):this.currentDate.day;
                    let dateStr = this.currentDate.year + "-" + month + "-" + day;
                    timestamp = dateStr;
                }
                else {
                    timestamp = "";
                }
                this.$emit("dateSelected", timestamp);
            },
            timestampToTime(timestamp) {
                let dateObject = {};
                if (typeof timestamp == "number") {
                    dateObject.year = new Date(timestamp).getFullYear();
                    dateObject.month = new Date(timestamp).getMonth() + 1;
                    dateObject.day = new Date(timestamp).getDate();
                    return dateObject;
                }
            },
            getFullYears() {
                for (let i = this.maxYear; i >= this.minYear; i--) {
                    this.years.push(i);
                }
            },
            getFullDays() {
                this.days = [];
                for (let i = 1; i <= this.normalMaxDays; i++) {
                    this.days.push(i);
                }
            },
            judgeDay() {
                if ([4, 6, 9, 11].indexOf(this.currentDate.month) !== -1) {
                    this.normalMaxDays = 30; //小月30天
                    if (this.currentDate.day && this.currentDate.day == 31) {
                        this.currentDate.day = "";
                    }
                } else if (this.currentDate.month == 2) {
                    if (this.currentDate.year) {
                        if (
                            (this.currentDate.year % 4 == 0 &&
                                this.currentDate.year % 100 != 0) ||
                            this.currentDate.year % 400 == 0
                        ) {
                            this.normalMaxDays = 29; //闰年2月29天
                        } else {
                            this.normalMaxDays = 28; //闰年平年28天
                        }
                    }
                    else {
                        this.normalMaxDays = 28;//闰年平年28天
                    }
                }
                else {
                    this.normalMaxDays = 31;//大月31天
                }
            }
        }
    };
</script>
<style scoped>

    .select {
        margin-right: 10px;
        width: 100px;
        text-align: center;
    }
    .month {
        width: 80px;
    }

    /deep/ .el-input__inner {
        height: 32px;
        background-color: #F0F2F5;
        border-radius: 5px;
    }


</style>