<template>
    <div class="school-from">
        <el-form :model="schoolInfo" :rules="schoolRules" ref="schoolForm" class="demo-ruleForm">
          <div style="margin-bottom: 10px"><span style="color: #FF0000">*</span><span style="color: #999999;">学校名称</span></div>
            <el-form-item prop="schoolName" style="margin-bottom: 20px">
                <el-input resize="none"
                          maxlength="50"
                          :show-word-limit="true"
                          v-model="schoolInfo.schoolName">
                </el-input>
            </el-form-item>
          <div style="display: flex">
            <div style="flex: 1; margin-right: 30px">
              <div style="margin-bottom: 10px"><span style="color: #FF0000">*</span><span style="color: #999999;">专业</span></div>
              <el-form-item prop="major" style="margin-bottom: 20px">
                <el-input type="text"
                          resize="none"
                          maxlength="20"
                          :show-word-limit="true"
                          v-model="schoolInfo.major">
                </el-input>
              </el-form-item>
            </div>
           <div style="flex: 1">
             <div style="margin-bottom: 10px"><span style="color: #FF0000">*</span><span style="color: #999999;">学位</span></div>
             <el-form-item prop="degree" style="margin-bottom: 20px">
               <el-input type="text"
                         resize="none"
                         maxlength="10"
                         :show-word-limit="true"
                         v-model="schoolInfo.degree">
               </el-input>
             </el-form-item>
           </div>

          </div>
          <div style="margin-bottom: 10px"><span style="color: #999999;">说明</span></div>
            <el-form-item prop="remarks" style="margin-bottom: 30px">
                <el-input type="textarea"
                          resize="none"
                          :rows="4"
                          v-model="schoolInfo.explain"
                          maxlength="500"
                          :show-word-limit="true">
                </el-input>
            </el-form-item>
            <div style="display: flex; margin: 10px 0">
                <div style="width: 300px; margin-top: 10px">
                  <el-radio v-model="schoolInfo.isGraduation" :label="1">已毕业</el-radio>
                  <el-radio v-model="schoolInfo.isGraduation" :label="0">未毕业</el-radio>
                </div>
                <div style="display: flex">
                    <el-form-item prop="time">
                        <div class="block">
                            <el-date-picker
                                size="small"
                                v-model="schoolInfo.time"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :unlink-panels="true">
                            </el-date-picker>
                        </div>
                    </el-form-item>
                </div>
            </div>
          <el-form-item prop="images">
            <div>
              <div style="margin-bottom: 10px"><span style="color: #FF0000">*</span><span style="color: #999999;">学历证书</span></div>
              <AuthImage :images="schoolInfo.images" @getImages="getImages"></AuthImage>

<!--                <div>-->
<!--                    <div style="font-size: 18px; margin-bottom: 18px"><span style="color: #FF0000">*</span>毕业证书（不对外展示）</div>-->
<!--                    <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">-->
<!--                        <el-upload :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple :http-request="($event)=>uploadFrontImg($event,0)">-->
<!--                            <img v-if="images.length > 0" width="100%" height="338px" :src="images[0]"/>-->
<!--                            <i class="el-icon-plus"></i>-->
<!--                            <div style="font-size: 20px">点击或拖动上传图片（10M以内）</div>-->
<!--                            <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>-->
<!--                        </el-upload>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <div style="font-size: 18px; margin-bottom: 18px"><span style="color: #FF0000">*</span>学历证书（不对外展示）</div>-->
<!--                    <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">-->
<!--                        <el-upload :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple :http-request="($event)=>uploadFrontImg($event,1)">-->
<!--                            <img v-if="images.length > 1" width="100%" height="338px" :src="images[1]"/>-->
<!--                            <i class="el-icon-plus"></i>-->
<!--                            <div style="font-size: 20px">点击或拖动上传图片（10M以内）</div>-->
<!--                            <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>-->
<!--                        </el-upload>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
          </el-form-item>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: end">
<!--                <el-checkbox v-model="schoolInfo.isOvert" true-label="1" false-label="0">对外公开</el-checkbox>-->
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo">取消填写</el-button>
                    <el-button class="save-btn" @click="saveInfo">保存信息</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import AuthImage from "@/pages/circle/components/Authentication/AuthImage.vue";

export default {
    name: "SchoolFrom",
    components: {AuthImage},
    data() {
        return {
            schoolInfo: this.schoolData,
            images: [],
            schoolRules: {
                schoolName: [
                    {required: true, message: '请填写学校名称', trigger: 'blur'}
                ],
                major: [
                    {required: true, message: '请填写专业名称', trigger: 'blur'}
                ],
                degree: [
                    {required: true, message: '请填写学名称', trigger: 'blur'}
                ],
                time: [
                    {required: true, message: '请填写时间范围', trigger: 'blur'}
                ],
                images: [
                  {required: true, message: '请上传学历证书', trigger: 'change'}
                ]
            },
        }
    },
    props: {
        schoolData: {
            type: Object,
            default() {
                return {
                    isGraduation: 1,
                    images: []
                }
            }
        },

        type: {
            type: String,
            default() {
                return '';
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
      this.images = this.schoolInfo.images
    },
    methods: {
      getImages(e) {
        this.images = e
      },
        cancelInfo() {
            this.$emit('reMajor', 'schoolInfo')
        },

        // 保存表单
        saveInfo() {
            let that = this;
            if (that.images.length > 0) {
              that.schoolInfo.images = that.images;
              this.$set(this.schoolRules, 'images', [])
            }
            that.$refs['schoolForm'].validate(function (valid) {
                if (valid) {
                    let params = {};
                    params = that.schoolInfo;
                    params.type = that.type;
                    params.isShow = 0;
                    that.newApi.setSchoolInfo({'schoolInfo': JSON.stringify(params)}).then(res => {
                        if (res.isSuccess === 1) {
                            that.$emit('reMajor', 'schoolInfo')
                        }
                    })
                }
            })
        },

        // 上传图片
        // uploadFrontImg(params, e) {
        //     var that = this;
        //     var file = params.file;
        //     const isLt5M = file.size / 1024 / 1024 < 10;
        //     if (isLt5M) {
        //         if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
        //             that.utils.upload(file,function(url){
	      //               if (!url){
		    //                 return false;
	      //               }
        //               if (that.images.length === 2) {
        //                 that.images.splice(e,1,url.url)
        //               } else {
        //                 that.images.push(url.url)
        //               }
        //             })
        //         } else {
        //             this.$message.error('只能上传图片类型!')
        //         }
        //
        //     } else {
        //         this.$message.error('上传文件大小不能超过 10MB!')
        //     }
        // }
    }
}
</script>

<style scoped>
.school-from {
    width: 100%;
}

.save-btn {
    width: 98px;
    height: 38px;
    color: #fff;
    background-color: #1C7EFF;
    border-radius: 5px;
}

.cancel-btn {
    width: 98px;
    height: 38px;
    margin-right: 18px;
    background-color: #E4E6EB;
    border-radius: 5px;
}

.resume-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
}

.upload-demo {
    vertical-align: middle;
}

.el-icon-plus {
    color: #2970FF;
    font-size: 44px;
    font-weight: 600;
    text-shadow: 0 3px 6px rgba(0,0,0,0.16);
    margin: 100px 0 14px 0;
}

/deep/ .el-upload-dragger {
    width: 516px;
    height: 338px;
    background-color: #FBFBFB;
}

/deep/ .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: #1C7EFF 2px solid;
}

/deep/ .el-checkbox__inner::after {
    left: 6px;
    height: 9px;
    width: 4px;
}

/deep/ .el-upload-dragger {
    width: 500px;
    height: 300px;
    background-color: #FBFBFB;
}
</style>
