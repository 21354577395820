import { render, staticRenderFns } from "./Work.vue?vue&type=template&id=0c8cbf98&scoped=true&"
import script from "./Work.vue?vue&type=script&lang=js&"
export * from "./Work.vue?vue&type=script&lang=js&"
import style0 from "./Work.vue?vue&type=style&index=0&id=0c8cbf98&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c8cbf98",
  null
  
)

export default component.exports