<template>
  <div class="professional">
    <div v-if="major === 'certification'">
      <div style="margin-bottom: 50px">
        <div style="display: flex; justify-content: space-between">
          <div class="resume-title">
            资质信息
          </div>
        </div>
        <div v-if="professionalTitleShow === false" class="hand op school-add" @click="professionalTitleShow = true">
          <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
          <span>添加资质信息</span>
        </div>
        <div v-if="professionalTitleShow === true" style="margin-bottom: 40px">
          <OrganFrom type="professionalInfo" @reMajor="getMajor" @pushOtherData="pushOtherData($event,majorInfo)"></OrganFrom>
        </div>
        <div v-for="(item, index) in majorInfo" :key="new Date().getTime() + index">
          <OrganData :otherData="item" type="professionalInfo" @reMajor="getMajor" @upOtherData="upOtherData($event,index,majorInfo)" @delOtherData="delOtherData(index,majorInfo)"></OrganData>
        </div>
      </div>
    </div>

    <div v-if="major === 'honorInfo'">
      <div style="margin-bottom: 50px">
        <div style="display: flex; justify-content: space-between">
          <div class="resume-title">
            获得荣誉
          </div>
        </div>
        <div v-if="honorShow === false" class="hand op school-add" @click="honorShow = true">
          <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
          <span>添加荣誉</span>
        </div>
        <div v-if="honorShow === true" style="margin-bottom: 40px">
          <OrganFrom type="honorInfo" @reMajor="getMajor" @pushOtherData="pushOtherData($event,majorInfo)"></OrganFrom>
        </div>
        <div v-for="(item, index) in majorInfo" :key="new Date().getTime() + index">
          <OrganData :otherData="item" type="honorInfo" @reMajor="getMajor" @upOtherData="upOtherData($event,index,majorInfo)" @delOtherData="delOtherData(index,majorInfo)"></OrganData>
        </div>
      </div>
    </div>

    <div v-if="major === 'otherInfo'">
      <div style="margin-bottom: 60px">
        <div class="resume-title">
          其他证书
        </div>
        <div v-if="otherInfoShow === false" class="hand op school-add" @click="otherInfoShow = true">
          <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
          <span>添加证书</span>
        </div>
        <div v-if="otherInfoShow === true" style="margin-bottom: 40px">
          <OrganFrom type="otherInfo" @reMajor="getMajor" @pushOtherData="pushOtherData($event,majorInfo)"></OrganFrom>
        </div>
        <div v-for="(item, index) in majorInfo" :key="new Date().getTime() + index">
          <OrganData :otherData="item" type="otherInfo" @reMajor="getMajor" @upOtherData="upOtherData($event,index,majorInfo)" @delOtherData="delOtherData(index,majorInfo)"></OrganData>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils";
import OrganData from "@/pages/circle/components/Authentication/OrganData.vue";
import OrganFrom from "@/pages/circle/components/Authentication/OrganFrom.vue";

export default {
  name: "OrganInfo",
  components: {OrganFrom, OrganData},
  data() {
    return {
      honorShow: false,

      professionalTitleShow: false,

      otherInfoShow: false,
    }
  },

  props: {
    major: {
      type: String,
      default () {
        return ''
      }
    },
    majorInfo: {
      type: Array,
      default() {
        return []
      }
    },

    majorData: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  computed: {
    utils() {
      return utils
    },
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {

  },

  methods: {
    // 编辑其他认证信息
    upOtherData(item, index, data, e) {
      data.splice(index, 1, item)
      let params = {};
      params = JSON.stringify(data)
      if (this.major === 'honorInfo') {
        this.newApi.setHonorInfo({honorInfo: params}).then(res => {
          if (res.isSuccess === 1) {
            this.getMajor(this.major)
          }
        })
      }
      if (this.major === 'otherInfo') {
        this.newApi.setOtherInfo({otherInfo: params}).then(res => {
          if (res.isSuccess === 1) {
            this.getMajor(this.major)
          }
        })
      }
      if (this.major === 'certification') {
        this.newApi.setQualification({qualification: params}).then(res => {
          if (res.isSuccess === 1) {
            this.getMajor('qualification')
          }
        })
      }

    },

    // 删除认证的证书
    delCartData(e, name) {

    },

    // 删除其他认证信息
    delOtherData(index, data) {
      data.splice(index, 1)
      let params = {};
      params = JSON.stringify(data)
      if (this.major === 'honorInfo') {
        this.newApi.setHonorInfo({honorInfo: params}).then(res => {
          if (res.isSuccess === 1) {
            this.getMajor(this.major)
          }
        })
      }
      if (this.major === 'otherInfo') {
        this.newApi.setOtherInfo({otherInfo: params}).then(res => {
          if (res.isSuccess === 1) {
            this.getMajor(this.major)
          }
        })
      }
      if (this.major === 'certification') {
        this.newApi.setQualification({qualification: params}).then(res => {
          if (res.isSuccess === 1) {
            this.getMajor('qualification')
          }
        })
      }
    },

    // 添加其他认证信息
    pushOtherData(item, data) {
      let params = {};
      data.push(item);
      params = JSON.stringify(data)
      if (this.major === 'honorInfo') {
        this.newApi.setHonorInfo({honorInfo: params}).then(res => {
          if (res.isSuccess === 1) {
            this.getMajor(this.major)
          }
        })
      }
      if (this.major === 'certification') {
        this.newApi.setQualification({qualification: params}).then(res => {
          if (res.isSuccess === 1) {
            this.getMajor('qualification')
          }
        })
      }
      if (this.major === 'otherInfo') {
        this.newApi.setOtherInfo({otherInfo: params}).then(res => {
          if (res.isSuccess === 1) {
            this.getMajor(this.major)
          }
        })
      }
    },

    // 获取专业认证信息
    getMajor(e) {
      var that = this;
      if (e === 'honorInfo') {
        that.honorShow = false;
      }
      if (e === 'qualification') {
        that.professionalTitleShow = false;
      }
      if (e === 'otherInfo') {
        that.otherInfoShow = false;
      }
      that.$emit('changeMajor')
    }
  },
}
</script>

<style scoped>
.resume-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
}

.school-add {
  color: #1C7EFF;
  font-size: 18px;
  width: 16%;
  margin-bottom: 20px;
}

.el-divider--horizontal {

}


/*公共*/

.hand{
  cursor:pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.op:active{
  opacity:0.6;
  -webkit-transition:all .1s;transition:all .1s;opacity:.6;
}

.op{
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .5s;
  transition: all .5s;
}
</style>
