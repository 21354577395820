<template>
  <div class="real-name" style="overflow: hidden">
    <div class="body-left">
      <div style="padding: 0 10px">
        <div @click="goMajor('archives')" class="hand op" :class="major === 'archives' ? 'tags-child-select': 'tags-child'">
          <img :src="user.headIco" style="width: 28px; height: 28px; border-radius: 50%; display: inline-block; vertical-align: middle; margin-right: 12px">
          <span style="font-size: 16px; margin-right: 20px;vertical-align: middle;">查看机构档案</span>
          <i class="el-icon-caret-right" style="font-size: 18px; font-weight: 600;vertical-align: middle;"></i>
        </div>
        <div @click="goMajor('person')" class="hand op" :class="major === 'person' ? 'tags-child-select': 'tags-child'">
          <span style="font-size: 16px; margin-right: 16px">机构信息</span>
        </div>
        <div @click="goMajor('certification')" class="hand op" :class="major === 'certification' ? 'tags-child-select': 'tags-child'">
          <span style="font-size: 16px; margin-right: 16px">机构资质</span>
        </div>
        <div @click="goMajor('honor')" class="hand op" style="position: relative" :class="major === 'honor' ? 'tags-child-select': 'tags-child'">
          <span style="font-size: 16px; margin-right: 16px;">机构荣誉</span>
        </div>
        <div @click="goMajor('other')" class="hand op" :class="major === 'other' ? 'tags-child-select': 'tags-child'">
          <span style="font-size: 16px; margin-right: 16px">其他证书</span>
        </div>
      </div>
    </div>

    <div class="body-right">
<!--      <div v-if="major !== 'archives'" class="progress">-->
<!--        <el-progress :percentage="percentage" color="#2970FF" text-color="#2970FF" :stroke-width="12"></el-progress>-->
<!--      </div>-->
      <div v-if="major === 'person'" style="background-color: #FFFFFF;min-height: 890px; padding: 20px; border-radius: 5px">
        <PersonInfo :data="userData"></PersonInfo>
      </div>
      <MyRecord v-if="major === 'archives'" :data="userData" @getArchives="getArchives"></MyRecord>
      <div v-if="major === 'certification'" style="padding: 20px; min-height: 830px; background-color: #FFFFFF; border-radius: 5px">
        <OrganInfo major="certification" @changeMajor="getArchives" :majorInfo="userData.qualification"></OrganInfo>
      </div>
      <div v-if="major === 'honor'" style="padding: 20px; min-height: 830px; background-color: #FFFFFF; border-radius: 5px">
        <OrganInfo major="honorInfo" @changeMajor="getArchives" :majorInfo="userData.honorInfo"></OrganInfo>
      </div>
      <div v-if="major === 'other'" style="padding: 20px; min-height: 830px; background-color: #FFFFFF; border-radius: 5px">
        <OrganInfo major="otherInfo" @changeMajor="getArchives" :majorInfo="userData.otherInfo"></OrganInfo>
      </div>
    </div>
    <div>

    </div>
  </div>
</template>
<script>
import MyRecord from "@/pages/circle/myRecord.vue";
import PersonInfo from "@/pages/index/archives/components/PersonInfo.vue";
import OrganInfo from "@/pages/circle/components/Authentication/OrganInfo.vue";

export default {
  name: 'Organ',
  components: {OrganInfo, PersonInfo, MyRecord},
  data() {
    return {
      auth: 1,
      userData: {},
      major: 'archives',
      percentage: 10
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
    this.getArchives()
  },

  methods: {
    // 获取档案信息
    getArchives() {
      this.newApi.getMyArchives().then((res)=>{
        this.userData = res.data
      })
    },

    changeMajor(e) {
      this.majorData = e
      this.getArchives()
    },
    // 获取专业保存信息
    getMajorSave() {
      var that = this;
      that.newApi.getUserMajorAuthTemp().then(res => {
        that.majorData = res.data;
      })
    },

    // 选择项
    goMajor(e) {
      this.major = e
      if (e === 'person') {
        this.getArchives()
      }
    }
  }
}
</script>

<style scoped>
.real-name {
  width: 100%;
}
.real-name .body-left {
  width: 340px;
  min-height: 890px;
  padding: 20px 0;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  float: left;
}

.real-name .body-right {
  width: 1150px;
  min-height: 890px;
  border-radius: 5px;
  float: left;
}

.real-name .update-btn {
  width: 140px;
  height: 24px;
  text-align: center;
  color: #2970FF;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 0;
  border-radius: 5px;
  border: 1px #2970FF solid
}

.real-name .up-btn {
  width: 140px;
  height: 24px;
  text-align: center;
  color: #FFFFFF;
  background-color: #FF9800;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 0;
  border-radius: 5px;
}

.real-name .tags-child {
  height: 44px;
  line-height: 44px;
  text-align: center;
}

.real-name .tags-child-select {
  height: 44px;
  line-height: 44px;
  color: #FFFFFF;
  text-align: center;
  background-color: #2970FF;
  border-radius: 5px;
}

.real-name .progress {
  height: 12px;
  padding: 18px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
}
</style>
