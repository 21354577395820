<template>
  <div class="real-name" style="overflow: hidden">
    <div class="body-left">
      <div style="padding: 0 10px">
        <div @click="goMajor('archives')" class="hand op" :class="major === 'archives' ? 'tags-child-select': 'tags-child'">
          <img :src="user.headIco" style="width: 28px; height: 28px; border-radius: 50%; display: inline-block; vertical-align: middle; margin-right: 12px">
          <span style="font-size: 16px; margin-right: 20px;vertical-align: middle;">查看我的档案</span>
          <i class="el-icon-caret-right" style="font-size: 18px; font-weight: 600;vertical-align: middle;"></i>
        </div>
        <div @click="goMajor('person')" class="hand op" :class="major === 'person' ? 'tags-child-select': 'tags-child'">
          <span style="font-size: 16px; margin-right: 16px">个人信息</span>
        </div>
        <div style="font-size: 18px; height: 40px; line-height: 40px; margin: 16px 18px 0 0; color: #031F88;  font-weight: 600; text-align: center; border-top: 1px #DCDCDC solid">专业认证</div>
        <div @click="goMajor('school')" class="hand op" style="position: relative" :class="major === 'school' ? 'tags-child-select': 'tags-child'">
          <img src="@/assets/imgs/index/zu3560.png" style="width: 18px; height: 20px; position: absolute; top: 12px; left: 90px">
          <span style="font-size: 16px; margin-right: 16px;">教育认证</span>
        </div>
<!--        <div @click="goMajor('work')" class="hand op" :class="major === 'work' ? 'tags-child-select': 'tags-child'">-->
<!--          <span class="content-tag" v-if="userData.workInfo.length === 0">* </span>-->
<!--          <span style="font-size: 16px; margin-right: 16px">工作经历</span>-->
<!--        </div>-->
<!--        <div @click="goMajor('project')" class="hand op" :class="major === 'project' ? 'tags-child-select': 'tags-child'">-->
<!--          <span class="content-tag" v-if="userData.projectInfo.length === 0">* </span>-->
<!--          <span style="font-size: 16px; margin-right: 16px">项目经历</span>-->
<!--        </div>-->
<!--        <div @click="goMajor('honor')" class="hand op" :class="major === 'honor' ? 'tags-child-select': 'tags-child'">-->
<!--          <span class="content-tag" v-if="userData.honorInfo.length === 0">* </span>-->
<!--          <span style="font-size: 16px; margin-right: 16px">获得荣誉</span>-->
<!--        </div>-->
        <div @click="goMajor('book')" class="hand op" style="position: relative" :class="major === 'book' ? 'tags-child-select': 'tags-child'">
          <img src="@/assets/imgs/index/zu3563.png" style="width: 18px; height: 20px; position: absolute; top: 12px; left: 90px">
          <span style="font-size: 16px; margin-right: 16px">专业认证</span>
        </div>
<!--        <div @click="goMajor('other')" class="hand op" :class="major === 'other' ? 'tags-child-select': 'tags-child'">-->
<!--          <span class="content-tag" v-if="userData.otherInfo.length === 0">* </span>-->
<!--          <span style="font-size: 16px; margin-right: 16px">其他佐证</span>-->
<!--        </div>-->
      </div>
    </div>

    <div class="body-right">
<!--      <div v-if="major !== 'archives'" class="progress">-->
<!--        <el-progress :percentage="percentage" color="#2970FF" text-color="#2970FF" :stroke-width="12"></el-progress>-->
<!--      </div>-->
      <MyRecord v-if="major === 'archives'" :data="userData" @getArchives="getArchives"></MyRecord>
      <div v-if="major === 'person'" style="background-color: #FFFFFF;min-height: 890px; padding: 20px; border-radius: 5px">
        <PersonInfo :data="userData"></PersonInfo>
      </div>

      <div v-if="major === 'school'" style="padding: 20px; min-height: 830px; background-color: #FFFFFF; border-radius: 5px">
        <School @changeMajor="getArchives" :majorInfo="userData.schoolInfo"></School>
      </div>
<!--      <div v-if="major === 'work'" style="padding: 20px; min-height: 830px; background-color: #FFFFFF; border-radius: 5px">-->
<!--        <Professional major="workInfo" @changeMajor="getArchives" :majorInfo="userData.workInfo"></Professional>-->
<!--      </div>-->
<!--      <div v-if="major === 'project'" style="padding: 20px; min-height: 830px; background-color: #FFFFFF; border-radius: 5px">-->
<!--        <Professional major="projectInfo" @changeMajor="getArchives" :majorInfo="userData.projectInfo"></Professional>-->
<!--      </div>-->
<!--      <div v-if="major === 'honor'" style="padding: 20px; min-height: 830px; background-color: #FFFFFF; border-radius: 5px">-->
<!--        <Professional major="honorInfo" @changeMajor="getArchives" :majorInfo="userData.honorInfo"></Professional>-->
<!--      </div>-->
<!--      <div v-if="major === 'other'" style="padding: 20px; min-height: 830px; background-color: #FFFFFF; border-radius: 5px">-->
<!--        <Professional major="otherInfo" @changeMajor="getArchives" :majorInfo="userData.otherInfo"></Professional>-->
<!--      </div>-->
      <div v-if="major === 'book'" style="padding: 20px; min-height: 830px; background-color: #FFFFFF; border-radius: 5px">
        <Professional @changeMajor="getArchives" :majorData="userData"></Professional>
      </div>

    </div>
    <div>

    </div>
  </div>
</template>
<script>
import Professional from "@/pages/circle/components/Professional.vue";
import MyRecord from "@/pages/circle/myRecord.vue";
import PersonInfo from "@/pages/index/archives/components/PersonInfo.vue";
import School from "@/pages/circle/components/Authentication/School.vue";
import Work from "@/pages/circle/components/Authentication/Work.vue";

export default {
  name: 'Person',
  components: {Work, School, PersonInfo, MyRecord, Professional},
  data() {
    return {
      auth: 1,
      userData: {},
      major: 'archives',
      percentage: 10,
      majorData: {
        schoolInfo: [],
        workInfo: [],
        projectInfo: [],
        honorInfo: [],
        professionalTitleInfo: [],
        NVQCartInfo: [],
        otherCartInfo: []
      },
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
    this.getArchives()
  },

  methods: {
    // 获取档案信息
    getArchives() {
      this.newApi.getMyArchives().then((res)=>{
        this.userData = res.data
      })
    },

    changeMajor(e) {
      this.majorData = e
      this.getArchives()
    },
    // 获取专业保存信息
    getMajorSave() {
      var that = this;
      that.newApi.getUserMajorAuthTemp().then(res => {
        that.majorData = res.data;
      })
    },

    // 选择项
    goMajor(e) {
      this.major = e
      if (e === 'person') {
        this.getArchives()
      }
    },

    // 提交专业审核
    updateMajorStatus() {
      this.newApi.saveExamineStatus({statusType: 2}).then(res => {
        if (res.isSuccess == 1) {
          this.utils.sus('提交成功，等待审核')
          this.user.majorAuthStatus = res.data.majorAuthStatus
        }
      })
    },
  }
}
</script>

<style scoped>
.real-name {
  width: 100%;
}
.real-name .body-left {
  width: 340px;
  min-height: 890px;
  padding: 20px 0;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  float: left;
}

.real-name .content-tag {
  color: red;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  top: 3px;
  left: 100px
}

.real-name .body-right {
  width: 1150px;
  min-height: 890px;
  border-radius: 5px;
  float: left;
}

.real-name .update-btn {
  width: 140px;
  height: 24px;
  text-align: center;
  color: #2970FF;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 0;
  border-radius: 5px;
  border: 1px #2970FF solid
}

.real-name .up-btn {
  width: 140px;
  height: 24px;
  text-align: center;
  color: #FFFFFF;
  background-color: #FF9800;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 0;
  border-radius: 5px;
}

.real-name .tags-child {
  height: 44px;
  line-height: 44px;
  text-align: center;
}

.real-name .tags-child-select {
  height: 44px;
  line-height: 44px;
  color: #FFFFFF;
  text-align: center;
  background-color: #2970FF;
  border-radius: 5px;
}

.real-name .progress {
  height: 12px;
  padding: 18px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
}
</style>
