<template>
  <div class="other-from">
    <el-form v-if="type === 'honorInfo'" :model="otherInfo" :rules="honorRules" :ref="type" class="demo-ruleForm">
      <el-form-item prop="name" style="margin-bottom: 20px">
        <div><span style="color: #FF0000">*</span><span style="color: #999999;">荣誉名称</span></div>
        <el-input type="text"
                  resize="none"
                  v-model="otherInfo.name"
                  maxlength="50"
                  :show-word-limit="true">
        </el-input>
      </el-form-item>
      <el-form-item prop="content" style="margin-bottom: 20px">
        <div><span style="color: #FF0000">*</span><span style="color: #999999;">荣誉详情</span></div>
        <el-input type="textarea"
                  resize="none"
                  :rows="4"
                  v-model="otherInfo.content"
                  maxlength="500"
                  :show-word-limit="true">
        </el-input>
      </el-form-item>
      <el-form-item prop="time">
        <div class="block">
          <div><span style="color: #FF0000">*</span><span style="color: #999999;">荣誉时间</span></div>
          <el-date-picker
              size="small"
              v-model="otherInfo.time"
              type="date"
              value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item prop="images">
        <div style="float: left">
          <div style="font-size: 18px; margin-bottom: 18px"><span style="color: #FF0000">*</span>荣誉证书内页（不对外展示）</div>
          <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
            <el-upload :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple :http-request="(event) =>uploadFrontImg(event,0)">
              <img v-if="images.length > 0" width="100%" height="338px" :src="images[0]"/>
              <i class="el-icon-plus"></i>
              <div style="font-size: 20px">点击或拖动上传图片（10M以内）</div>
              <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
            </el-upload>
          </div>
        </div>
        <div style="float: right; margin-top: 54px">
          <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px;">
            <el-upload :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple :http-request="(event) => uploadFrontImg(event,1)">
              <img v-if="images.length > 1" width="100%" height="338px" :src="images[1]"/>
              <i class="el-icon-plus"></i>
              <div style="font-size: 20px">点击或拖动上传图片（10M以内）</div>
              <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
            </el-upload>
          </div>
        </div>
      </el-form-item>

      <el-divider></el-divider>
      <div style="display: flex; justify-content: end">
        <div>
          <el-button class="cancel-btn" @click="cancelInfo">取消填写</el-button>
          <el-button class="save-btn" @click="saveInfo" v-if="!isEdit">保存信息</el-button>
          <el-button class="save-btn" @click="upInfo" v-else>保存信息</el-button>
        </div>
      </div>
    </el-form>

    <el-form v-if="type === 'professionalInfo'" :model="otherInfo" :rules="professionalTitleRules" :ref="type" class="demo-ruleForm">
      <el-form-item prop="name" style="margin-bottom: 20px; margin-right: 20px">
        <div><span style="color: #FF0000">*</span><span style="color: #999999;">资质名称</span></div>
        <el-input type="text"
                  resize="none"
                  maxlength="50"
                  :show-word-limit="true"
                  v-model="otherInfo.name">
        </el-input>
      </el-form-item>
      <el-form-item prop="range" style="margin-bottom: 30px;">
        <div><span style="color: #FF0000">*</span><span style="color: #999999;">资质类别</span></div>
        <el-input type="textarea"
                  resize="none"
                  :rows="5"
                  placeholder=""
                  v-model="otherInfo.range"
                  :show-word-limit="true">
        </el-input>
      </el-form-item>

      <div style="display: flex;">
        <div class="resume-title">
          等级-有效期
        </div>
        <el-form-item prop="lv" style="margin: 0 0 30px 100px;">
          <div style="line-height: 30px">
            <el-radio v-model="otherInfo.lv" :label="1">中级</el-radio>
            <el-radio v-model="otherInfo.lv" :label="2">高级</el-radio>
            <el-radio v-model="otherInfo.lv" :label="3">提高</el-radio>
          </div>
        </el-form-item>
        <div style="margin-left: 50px">
          <el-form-item prop="time" style="margin: 0 0 30px 100px;">
            <el-date-picker
                size="small"
                v-model="otherInfo.time"
                type="date"
                placeholder="资质有效期"
                value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
        </div>
      </div>
      <div>
        <div style="font-size: 18px; margin-bottom: 18px"><span style="color: #FF0000">*</span>资质证书（不对外展示）</div>
        <el-form-item prop="images">
          <div style="display: flex">
            <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
              <el-upload :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple :http-request="(event) => uploadFrontImg(event,0)">
                <img v-if="images.length > 0" width="100%" height="338px" :src="images[0]"/>
                <i class="el-icon-plus"></i>
                <div style="font-size: 20px">点击或拖动上传图片（10M以内）</div>
                <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
              </el-upload>
            </div>
            <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
              <el-upload :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple :http-request="(event) => uploadFrontImg(event,1)">
                <img v-if="images.length > 1" width="100%" height="338px" :src="images[1]"/>
                <i class="el-icon-plus"></i>
                <div style="font-size: 20px">点击或拖动上传图片（10M以内）</div>
                <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
              </el-upload>
            </div>
          </div>
        </el-form-item>
      </div>
      <el-divider></el-divider>
      <div style="display: flex; justify-content: end">
        <div>
          <el-button class="cancel-btn" @click="cancelInfo">取消填写</el-button>
          <el-button class="save-btn" @click="saveInfo" v-if="!isEdit">保存信息</el-button>
          <el-button class="save-btn" @click="upInfo" v-else>保存信息</el-button>
        </div>
      </div>
    </el-form>

    <el-form v-if="type === 'otherInfo'" :model="otherInfo" :rules="otherRules" :ref="type" class="demo-ruleForm">
      <el-form-item prop="name" style="margin-bottom: 20px">
        <div><span style="color: #FF0000">*</span><span style="color: #999999;">证书名称</span></div>
        <el-input type="text"
                  resize="none"
                  maxlength="50"
                  :show-word-limit="true"
                  v-model="otherInfo.name">
        </el-input>
      </el-form-item>
      <el-form-item prop="content" style="margin-bottom: 20px">
        <div><span style="color: #FF0000">*</span><span style="color: #999999;">说明</span></div>
        <el-input type="textarea"
                  resize="none"
                  :rows="4"
                  v-model="otherInfo.content"
                  maxlength="500"
                  :show-word-limit="true">
        </el-input>
      </el-form-item>
      <div>
        <div style="font-size: 18px; margin-bottom: 18px"><span style="color: #FF0000">*</span>证书图片（不对外展示）</div>
        <el-form-item prop="images">
          <div style="display: flex">
            <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
              <el-upload :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple :http-request="(event) =>uploadFrontImg(event,0)">
                <img v-if="images.length > 0" width="100%" height="338px" :src="images[0]"/>
                <i class="el-icon-plus"></i>
                <div style="font-size: 20px">点击或拖动上传图片（10M以内）</div>
                <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
              </el-upload>
            </div>
            <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
              <el-upload :show-file-list="false" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" drag multiple :http-request="(event) =>uploadFrontImg(event,1)">
                <img v-if="images.length > 1" width="100%" height="338px" :src="images[1]"/>
                <i class="el-icon-plus"></i>
                <div style="font-size: 20px">点击或拖动上传图片（10M以内）</div>
                <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
              </el-upload>
            </div>
          </div>
        </el-form-item>
      </div>
      <el-divider></el-divider>
      <div style="display: flex; justify-content: end">
        <div>
          <el-button class="cancel-btn" @click="cancelInfo">取消填写</el-button>
          <el-button class="save-btn" @click="saveInfo" v-if="!isEdit">保存信息</el-button>
          <el-button class="save-btn" @click="upInfo" v-else>保存信息</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>

import Editor from "@/pages/circle/components/Editor.vue";

export default {
  name: "OrganFrom",
  components: {Editor},
  data() {
    return {
      otherInfo: this.otherData,
      images: [],
      cartImg: '',

      honorRules: {
        name: [
          {required: true, message: '请填写荣誉名称', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请填写荣誉详情', trigger: 'blur'}
        ],
        time: [
          {required: true, message: '请填写荣誉时间', trigger: 'blur'}
        ],
        images: [
          {required: true, message: '请上传荣誉证书', trigger: 'change'}
        ]
      },

      professionalTitleRules: {
        name: [
          {required: true, message: '请填写资质名称', trigger: 'blur'}
        ],
        range: [
          {required: true, message: '请填写业务范围', trigger: 'blur'}
        ],
        time: [
          {required: true, message: '请填写资质有效期', trigger: 'blur'}
        ],
        lv: [
          {required: true, message: '请选择资质等级', trigger: 'change'}
        ],
        images: [
          {required: true, message: '请上传资质证书', trigger: 'change'}
        ],
      },

      otherRules: {
        name: [
          {required: true, message: '请填写证书名称', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请填写说明情况', trigger: 'blur'}
        ],
        images: [
          {required: true, message: '请上传证书', trigger: 'blur'}
        ]
      }

    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  props: {
    otherData: {
      type: Object,
      default() {
        return {}
      }
    },

    type: {
      type: String,
      default() {
        return '';
      }
    },

    isEdit: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  created: function () {

  },
  mounted: function () {
    if (this.isEdit && this.otherInfo.images) {
      this.images = this.otherInfo.images
    }
  },
  methods: {
    cancelInfo() {
      this.$emit('reMajor', this.type)
    },

    // 保存表单
    saveInfo() {
      let that = this;
      if (that.images.length > 0) {
        that.otherInfo.images = that.images;
        this.$set(this.honorRules, 'images', [])
        this.$set(this.otherRules, 'images', [])
        this.$set(this.professionalTitleRules, 'images', [])
      }
      that.$refs[that.type].validate(function (valid) {
        if (valid) {
          let params = {};
          params = that.otherInfo;
          that.$emit('pushOtherData', params)
        }
      })
    },

    // 编辑表单
    upInfo() {
      let that = this;
      if (that.images.length > 0) {
        that.otherInfo.images = that.images;
      }
      that.$refs[that.type].validate(function (valid) {
        if (valid) {
          let params = {};
          params = that.otherInfo;
          that.$emit('upOtherData', params)
        }
      })
    },

    // 上传图片
    uploadFrontImg(params, e) {
      var that = this;
      var file = params.file;
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (isLt5M) {
        if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
          that.utils.upload(file,function(url){
            if (!url){
              return false;
            }
            if (that.images.length === 2) {
              that.images.splice(e,1,url.url)
            } else {
              that.images.push(url.url)
            }
          })
        } else {
          this.$message.error('只能上传图片类型!')
        }

      } else {
        this.$message.error('上传文件大小不能超过 10MB!')
      }
    }
  }
}
</script>

<style scoped>
.open-btn {
  width: 76px;
  height: 38px;
  padding: 2px 0;
  line-height: 6px;
  background-color: #E4E6EB;
  border-radius: 5px;
}

.save-btn {
  width: 98px;
  height: 38px;
  color: #fff;
  background-color: #1C7EFF;
  border-radius: 5px;
}

.cancel-btn {
  width: 98px;
  height: 38px;
  margin-right: 18px;
  background-color: #E4E6EB;
  border-radius: 5px;
}

.resume-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.upload-demo {
  vertical-align: middle;
}

.el-icon-plus {
  color: #2970FF;
  font-size: 44px;
  font-weight: 600;
  text-shadow: 0 3px 6px rgba(0,0,0,0.16);
  margin: 100px 0 14px 0;
}

/deep/ .el-upload-dragger {
  width: 516px;
  height: 338px;
  background-color: #FBFBFB;
}

/deep/ .el-checkbox__inner {
  width: 18px;
  height: 18px;
  border: #1C7EFF 2px solid;
}

/deep/ .el-checkbox__inner::after {
  left: 6px;
  height: 9px;
  width: 4px;
}

/deep/ .el-upload-dragger {
  width: 500px;
  height: 300px;
  background-color: #FBFBFB;
}
</style>
