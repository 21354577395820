<template>
  <div class="person-info">
    <div style="margin-bottom: 30px">
      <el-row :gutter="24">
        <el-col :span="12">
          <div class="title-name">
            姓名
          </div>
          <div class="content-box">
            {{userData.realName}}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="title-name">
            手机号码
          </div>
          <div class="content-box">
            {{userData.phone}}
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <div class="title-name">
        个人信息
      </div>
      <div v-if="showEdit === true">
        <el-row :gutter="24">
          <el-col :span="12">
            <span style="color: #FF0000">*</span><span style="color: #999999">常驻地址</span>
            <div style="margin-top: 10px">
              <address-select :address="userData.address" @change="selectCity" :lv="2" :multiple="false"></address-select>
            </div>
          </el-col>
          <el-col :span="12">
            <span style="color: #FF0000">*</span><span style="color: #999999">专业领域</span>
            <div style="margin-top: 10px">
              <el-cascader
                  style="width: 500px"
                  :options="options"
                  :props="props"
                  :checkStrictly="true"
                  v-model="userData.industryType"
                  :multiple-limit="5"
                  clearable></el-cascader>
            </div>
          </el-col>
        </el-row>
        <div style="margin-top: 20px">
          <span style="color: #FF0000">*</span><span style="color: #999999">业务技能</span>
          <div style="margin-top: 10px">
            <el-input type="textarea"
                      resize="none"
                      :rows="2"
                      placeholder="业务技能"
                      v-model="userData.skills"
                      minlength="10"
                      maxlength="50"
                      :show-word-limit="true"
                      style="margin-bottom: 16px">
            </el-input>
          </div>
        </div>
        <el-divider></el-divider>
        <div style="display: flex; justify-content: end">
          <div class="hand op btn" @click="showEdit = false" style="margin-right: 20px; background-color: #E4E6EB">取消填写</div>
          <div class="hand op btn" @click="saveInfo" style="background-color: #2E73FF; color: #FFFFFF">保存信息</div>
        </div>
      </div>
      <div v-else>
        <div class="text-box">
          <div style="position: absolute; top: 20px; right: 20px; display: flex">
            <img class="hand op" @click="showEdit = true" src="@/assets/imgs/circle/editorC.png" style="width: 30px; height: 30px; margin-right: 20px">
            <div style="width: 88px;text-align: center; padding: 3px 0; line-height: 20px; border-radius: 20px;border: 1px solid #BDBFC1;">
              <el-checkbox v-model="isShow" @change="changeShow">展示</el-checkbox>
            </div>
          </div>
          <div style="font-size: 16px; padding: 20px">
            <div style="margin-bottom: 18px"><span style="color: #999999">常驻地址：</span><span v-if="userData.address.cityName">{{ userData.address.cityName[0] + ' / ' +  userData.address.cityName[1]}}</span></div>
            <div style="margin-bottom: 18px"><span style="color: #999999">专业领域：</span><span v-for="(item, index) in industryShow" :key="index"><span style="font-size: 16px; margin-right: 20px">{{item}}</span></span></div>
            <div style="margin-bottom: 18px"><span style="color: #999999">业务技能：</span><span>{{userData.skills}}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import AddressSelect from "@/components/common/city/AddressSelect.vue";

export default {
  name: 'PersonInfo',
  components: {AddressSelect},
  data() {
    return {
      showEdit: false,
      isShow: false,
      options: [],
      industryShow: [],
      userData: {},
      props: { multiple: true},
    }
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  created() {
    this.userData = this.data
    let list = []
    let showList = []
    let newList = []
    this.industryShow = []
    this.newApi.getTypeList({lv: 3}).then(res => {
      this.options = res.data;
      for (const item of this.userData.industryType) {
        list.push(item.typeArr[2])
        showList.push(item.typeArr)
      }
      this.userData.industryType = showList
      newList = this.utils.cascader(list, this.options, 'value');
      for (const item of newList) {
        this.industryShow.push(item[2])
      }
    })
  },

  mounted() {

  },

  methods: {
    selectCity(address) {
      this.userData.address = address
    },

    // 修改展示
    changeShow() {

    },

    // 获取类型
    getTypeList() {
      let list = []
      let showList = []
      let newList = []
      this.industryShow = []
      for (const item of this.userData.industryType) {
        list.push(item[2])
        showList.push(item)
      }
      this.userData.industryType = showList
      newList = this.utils.cascader(list, this.options, 'value');
      for (const item of newList) {
        this.industryShow.push(item[2])
      }
    },

    // 保存信息
    saveInfo() {
      let params = {};
      params.address = JSON.stringify(this.userData.address);
      params.industryType = JSON.stringify(this.userData.industryType);
      params.businessSkills = this.userData.skills;
      this.newApi.setMyInfo(params).then(res => {
        if (res.isSuccess === 1) {
          this.utils.sus(res.data);
          this.showEdit = false;
          this.getTypeList()
        }
      })
    },
  }
}
</script>
<style scoped>
.person-info .title-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.person-info .content-box {
  width: 500px;
  height: 44px;
  line-height: 44px;
  padding-left: 20px;
  background: #F5F7FA;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #BDBFC1;
}

.person-info .btn {
  width: 108px;
  text-align: center;
  padding: 6px 0;
  border-radius: 5px;
}

.person-info .text-box {
  width: 1110px;
  height: 150px;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
  border-radius: 5px;
}

.person-info .el-checkbox{
  margin-right:0;
}
</style>

<style lang="scss">
.el-cascader-panel {
  .el-scrollbar:first-child {
    .el-checkbox { display: none; }
  }
  .el-scrollbar:nth-child(2) {
    .el-checkbox { display: none; }
  }
}

</style>
