<template>
    <div class="visible">
        <el-button class="open-btn" @click="updateShow = true">
            <span v-if="visibleInfo === '1'">
                <img src="../../../assets/imgs/circle/open.png" style="width: 16px; height: 16px; vertical-align: middle; margin-right: 2px"/>
                <span style="vertical-align: middle">公开</span>
            </span>
            <span v-if="visibleInfo === '0'">
                <img src="../../../assets/imgs/circle/mine.png" style="width: 20px; height: 20px; vertical-align: middle;"/>
                <span style="vertical-align: middle">自己</span>
            </span>
        </el-button>
        <el-dialog title="选择分享对象" :visible.sync="updateShow" width="24%" center>
            <el-divider></el-divider>
            <div style="padding: 10px;">
                <div class="tags">
                    <div class="icon-tittle">
                        <div class="icon">
                            <img src="../../../assets/imgs/circle/visible1.png" class="icon"/>
                        </div>
                        <div class="content">
                            <div class="tittle">公开</div>
                            <div class="info">百工宝站内任何人都可见</div>
                        </div>
                    </div>
                    <div class="btn-select">
                        <el-radio v-model="visibleInfo" label="1" @change="changeVisible"></el-radio>
                    </div>
                </div>

                <div class="tags">
                    <div class="icon-tittle">
                        <div class="icon">
                            <img src="../../../assets/imgs/circle/visible3.png" class="icon"/>
                        </div>
                        <div class="content">
                            <div class="tittle">不公开</div>
                            <div class="info">仅自己可见</div>
                        </div>
                    </div>
                    <div class="btn-select">
                        <el-radio v-model="visibleInfo" label="0" @change="changeVisible"></el-radio>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Visible",
        props: {
            visible: {
                type: String,
                default() {
                    return '1'
                }
            },
        },
        data() {
            return {
                visibleInfo: this.visible,
                updateComShow: '',
                updateShow: false,
            }
        },

        mounted() {
            this.changeVisible(this.visibleInfo)
        },

        methods: {
            changeVisible(e) {
                this.$emit('selectVisible', e)
            },

        },
    }
</script>

<style scoped>
    .open-btn {
        width: 76px;
        height: 38px;
        padding: 2px 0;
        line-height: 6px;
        background-color: #E4E6EB;
        border-radius: 5px;
    }

    .tags {
        height: 64px;
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
        padding: 2px 10px;
        margin-bottom: 20px;
    }

    .tags:hover {
        background-color: #EAF3FF;
    }

    .icon-tittle {
        display: flex;
    }

    .icon {
        width: 62px;
        height: 62px;
        margin-right: 10px;
    }

    .tittle {
        font-size: 18px;
        font-weight: 600;
        line-height: 36px;
    }

    .info {
        color: #999999;
    }

    .btn-select {
        margin-top: 20px;
    }

    /deep/ .el-dialog__body {
        padding: 0;
    }

    /deep/ .el-dialog__header {
        margin: 0 4% 0 0;
        padding: 10px 0 0 0;
        font-weight: 600;
    }

    /deep/ .el-dialog__headerbtn {
        top: 12px;
        font-size: 18px;
    }

    /deep/ .el-divider--horizontal {
        margin: 10px 0;
    }

    /deep/ .el-radio__inner {
       width: 20px;
        height: 20px;
    }

    /deep/ .el-radio__label {
        display: none;
    }
</style>