<template>
  <div class="other-data">
    <div class="content" v-if="type ==='honorInfo' && updateShow === false">
      <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
        <div style="width: 72%">
          <span class="content-title">荣誉名称：</span>
          <span>{{otherInfo.name}}</span>
        </div>
        <div style="display: flex">
          <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="updateShow = true">
            <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
          </div>
          <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="deleteInfo">
            <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
          </div>
          <div style="width: 88px;text-align: center; line-height: 20px; padding: 4px 0; border-radius: 20px;border: 1px solid #BDBFC1;">
            <el-checkbox v-model="otherInfo.isShow" @change="changeShow" :true-label="1" :false-label="0">展示</el-checkbox>
          </div>
        </div>
      </div>
      <div class="info">
        <span class="content-title">荣誉说明：</span>
        <span>{{otherInfo.content}}</span>
      </div>
      <div class="info">
        <span class="content-title">获得荣誉时间：</span>
        <span>{{otherInfo.time}}</span>
      </div>
      <div style="display: flex; justify-content: space-between" v-if="otherInfo.images">
        <div v-if="otherInfo.images.length > 0">
          <div style="font-size: 18px; margin-bottom: 18px" class="content-title-img">荣誉证书（不对外展示）</div>
          <img :src="otherInfo.images[0]" style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px;">
        </div>
        <div v-if="otherInfo.images.length > 1">
          <div style="font-size: 18px; margin-bottom: 18px" class="content-title-img">荣誉证书（不对外展示）</div>
          <img :src="otherInfo.images[1]" style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px;">
        </div>
      </div>

    </div>
    <OrganFrom v-if="type === 'honorInfo' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="type" :otherData="otherInfo" :isEdit="true"></OrganFrom>

    <div class="content" v-if="type ==='professionalInfo' && updateShow === false">
      <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
        <div style="width: 72%">
          <span class="content-title">资质名称：</span>
          <span>{{otherInfo.name}}</span>
        </div>
        <div style="display: flex">
          <div class="hand op" style="margin-right: 10px" @click="updateShow = true">
            <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
          </div>
          <div class="hand op" style="margin-right: 10px" @click="deleteInfo">
            <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
          </div>
          <div style="width: 88px;text-align: center; line-height: 20px; padding: 4px 0; border-radius: 20px;border: 1px solid #BDBFC1;">
            <el-checkbox v-model="otherInfo.isShow" @change="changeShow" :true-label="1" :false-label="0">展示</el-checkbox>
          </div>
        </div>
      </div>
      <div class="info">
        <span class="content-title">资质类别：</span>
        <span>{{otherInfo.range}}</span>
      </div>
      <div class="info">
        <span class="content-title">资质等级：</span>
        <span v-if="otherInfo.lv === 1">中级</span>
        <span v-if="otherInfo.lv === 2">高级</span>
        <span v-if="otherInfo.lv === 3">提高</span>
      </div>
      <div class="info">
        <span class="content-title">资质有效期：</span>
        <span>{{otherInfo.time}}</span>
      </div>
      <div>
        <div style="display: flex; justify-content: space-between" v-if="otherInfo.images">
          <div v-if="otherInfo.images.length > 0">
            <div style="font-size: 18px; margin-bottom: 18px" class="content-title-img">资质证书（不对外展示）</div>
            <img :src="otherInfo.images[0]" style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px;">
          </div>
          <div v-if="otherInfo.images.length > 1">
            <div style="font-size: 18px; margin-bottom: 18px" class="content-title-img">资质证书（不对外展示）</div>
            <img :src="otherInfo.images[1]" style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px;">
          </div>
        </div>
      </div>
    </div>
    <OrganFrom v-if="type === 'professionalInfo' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="type" :otherData="otherInfo" :isEdit="true"></OrganFrom>

    <div class="content" v-if="type ==='otherInfo' && updateShow === false">
      <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
        <div style="width: 72%">
          <span class="content-title">证书名称：</span>
          <span>{{otherInfo.name}}</span>
        </div>
        <div style="display: flex">
          <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="updateShow = true">
            <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
          </div>
          <div v-if="user.majorAuthStatus !== 1" class="hand op" style="margin-right: 10px" @click="deleteInfo">
            <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
          </div>
          <div style="width: 88px;text-align: center; line-height: 20px; padding: 4px 0; border-radius: 20px;border: 1px solid #BDBFC1;">
            <el-checkbox v-model="otherInfo.isShow" @change="changeShow" :true-label="1" :false-label="0">展示</el-checkbox>
          </div>
        </div>
      </div>
      <div class="info">
        <span class="content-title">说明：</span>
        <span>{{otherInfo.content}}</span>
      </div>
      <div style="display: flex; justify-content: space-between" v-if="otherInfo.images">
        <div v-if="otherInfo.images.length > 0">
          <div style="font-size: 18px; margin-bottom: 18px" class="content-title-img">证书图片（不对外展示）</div>
          <img :src="otherInfo.images[0]" style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px;">
        </div>
        <div v-if="otherInfo.images.length > 1">
          <div style="font-size: 18px; margin-bottom: 18px" class="content-title-img">证书图片（不对外展示）</div>
          <img :src="otherInfo.images[1]" style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px;">
        </div>
      </div>
    </div>
    <OrganFrom v-if="type === 'otherInfo' && updateShow === true" @reMajor="getMajor" @upOtherData="updateChange" :type="type" :otherData="otherInfo" :isEdit="true"></OrganFrom>
  </div>
</template>

<script>

import OrganFrom from "@/pages/circle/components/Authentication/OrganFrom.vue";
export default {
  name: "OrganData",
  components: {OrganFrom},
  data() {
    return {
      otherInfo: {},
      updateShow: false,
    }
  },
  props: {
    otherData: {
      type: Object,
      default() {
        return {}
      }
    },

    type: {
      type: String,
      default() {
        return '';
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  created: function () {

  },
  mounted: function () {
    this.otherInfo = this.otherData
  },
  methods: {
    // 提交专业认证
    submitMajor(e) {
      let that = this;
      that.utils.confirm('请确认提交审核内容，审核通过后将无法修改', function () {
        that.newApi.submitMajorAudit({type: e, name: that.otherInfo.name}).then(res => {
          if (res.isSuccess === 1) {
            that.$emit('reMajor')
          }
        })
      })
    },

    // 修改展示
    changeShow() {
      this.$emit('upOtherData', this.otherInfo)
    },

    // 删除
    deleteInfo() {
      this.$emit('delOtherData')
    },

    // 更新
    updateChange(item) {
      this.updateShow = false;
      this.$emit('upOtherData', item)
    },

    getMajor() {
      this.updateShow = false;
      this.$emit('reMajor', this.otherData.type)
    },
  }
}
</script>

<style scoped>
.other-data {
  margin-bottom: 10px;
}

.content {
  font-size: 18px;
  padding: 20px;
  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #BDBFC1;
}

.info {
  margin-bottom: 20px;
}

.content-title {
  color: #999999;
}

.content-title-img {
  font-weight: 600;
}

.replay-btn {
  width: 120px;
  text-align: center;
  font-size: 14px;
  padding: 6px 0;
  border-radius: 20px;
  margin-left: 10px;
  color: #FFFFFF;
}

.other-data .info>>>img {
  max-width: 100%;
  max-height: 100%;
}

/*公共*/
.hand{
  cursor:pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.op:active{
  opacity:0.6;
  -webkit-transition:all .1s;transition:all .1s;opacity:.6;
}

.op{
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .5s;
  transition: all .5s;
}
</style>
