<template>
    <div class="school-info">
        <el-form v-if="tags === 'education'" :model="schoolInfo" :rules="schoolRules" ref="schoolForm" class="demo-ruleForm">
            <el-form-item prop="schoolName" style="margin-bottom: 10px">
                    <el-input type="textarea"
                              resize="none"
                              :rows="2"
                              placeholder="学校名称"
                              v-model="schoolInfo.schoolName"
                              style="margin-bottom: 16px">
                    </el-input>
            </el-form-item>
            <el-form-item prop="major" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="专业"
                          v-model="schoolInfo.major"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="degree" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="学位"
                          v-model="schoolInfo.degree"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="remarks" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="4"
                          placeholder="说明"
                          v-model="schoolInfo.remarks"
                          minlength="10"
                          maxlength="50"
                          :show-word-limit="true"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <div class="resume-title">
                时间段
            </div>
            <div style="display: flex; margin-bottom: 16px">
                <div style="width: 20%">
                    <span style="margin-right: 20px; font-size: 16px">已毕业</span>
                    <el-checkbox v-model="schoolInfo.isGraduation"></el-checkbox>
                </div>
                <div style="display: flex">
                    <div style="margin-right: 10px">
                        <DatePicker @dateSelected="getStartTime"></DatePicker>
                    </div>
                    <span>至</span>
                    <div style="margin-left: 10px">
                        <DatePicker @dateSelected="getEndTime"></DatePicker>
                    </div>
                </div>
            </div>
            <div style="display: flex">
                <div>
                    <div style="font-size: 18px; margin-bottom: 18px">毕业证书（不对外展示）</div>
                    <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
                        <el-upload accept="image/*"  class="upload-demo" action="" drag multiple :limit='1' :http-request="($event)=>uploadFrontImg($event,'educationFront')" :on-remove="$event => removeImg($event, 'educationFront')">
                            <img v-if="imgFront" width="100%" height="338px" :src="imgFront"/>
                            <i class="el-icon-plus"></i>
                            <div style="font-size: 20px">点击或拖动上传图片（5M以内）</div>
                            <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
                        </el-upload>
                    </div>
                </div>
                <div>
                    <div style="font-size: 18px; margin-bottom: 18px">毕业证书（不对外展示）</div>
                    <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
                        <el-upload accept="image/*"  class="upload-demo" action="" drag multiple :limit='1' :http-request="($event)=>uploadFrontImg($event,'educationBack')" :on-remove="$event => removeImg($event, 'educationBack')">
                            <img v-if="imgBack" width="100%" height="338px" :src="imgBack"/>
                            <i class="el-icon-plus"></i>
                            <div style="font-size: 20px">点击或拖动上传图片（5M以内）</div>
                            <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
                        </el-upload>
                    </div>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <Visible :visible="schoolInfo.isOvert" @selectVisible="e => {schoolInfo.isOvert = e}"></Visible>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="tags === 'work'" :model="workInfo" :rules="workRules" ref="workForm" class="demo-ruleForm">
            <el-form-item prop="company" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="公司名称"
                          v-model="workInfo.company"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="position" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="职位"
                          v-model="workInfo.position"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="companyAdress" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="地址"
                          v-model="workInfo.companyAdress"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="remark" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="4"
                          placeholder="说明"
                          v-model="workInfo.remarks"
                          minlength="10"
                          maxlength="50"
                          :show-word-limit="true"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <div class="resume-title">
                时间段
            </div>
            <div style="display: flex; margin-bottom: 16px">
                <div style="width: 20%">
                    <span style="margin-right: 20px; font-size: 16px">目前在职</span>
                    <el-checkbox v-model="workInfo.isLeave" true-label="1" false-label="0"></el-checkbox>
                </div>
                <div style="display: flex">
                    <div style="margin-right: 10px">
                        <DatePicker @dateSelected="getStartWorkTime"></DatePicker>
                    </div>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <Visible :visible="workInfo.isOvert" @selectVisible="e => {workInfo.isOvert = e}"></Visible>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="tags === 'project'" :model="projectInfo" :rules="projectRules" ref="projectForm" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="项目名称"
                          v-model="projectInfo.name"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="content" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="4"
                          placeholder="项目实施详情"
                          v-model="projectInfo.content"
                          minlength="10"
                          maxlength="50"
                          :show-word-limit="true"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <Visible :visible="projectInfo.isOvert" @selectVisible="e => {projectInfo.isOvert = e}"></Visible>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="tags === 'honor'" :model="honorInfo" :rules="honorRules" ref="honorForm" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="获得荣誉"
                          v-model="honorInfo.name"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <el-form-item prop="content" style="margin-bottom: 10px">
                <el-input type="textarea"
                          resize="none"
                          :rows="4"
                          placeholder="获得荣誉详情"
                          v-model="honorInfo.content"
                          minlength="10"
                          maxlength="50"
                          :show-word-limit="true"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <div>
                <div style="font-size: 18px; margin-bottom: 18px">荣誉证书（不对外展示）</div>
                <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
                    <el-upload accept="image/*"  class="upload-demo" action="" drag multiple :limit='1' :http-request="($event)=>uploadFrontImg($event,tags)">
                        <img v-if="honorInfo.honorCart.length !== 0" width="100%" height="338px" :src="honorInfo.honorCart[0]"/>
                        <i class="el-icon-plus"></i>
                        <div style="font-size: 20px">点击或拖动上传图片（5M以内）</div>
                        <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
                    </el-upload>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <Visible :visible="honorInfo.isOvert" @selectVisible="e => {honorInfo.isOvert = e}"></Visible>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="tags === 'certificateRank'" :model="professionalTitleInfo" ref="professionalTitleForm" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 16px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="证书名称"
                          v-model="professionalTitleInfo.name"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <div style="display: flex">
                <div class="resume-title">
                    职称等级
                </div>
                <div style="margin-bottom: 26px; margin-left: 120px">
                    <el-radio v-model="professionalTitleInfo.lv" label="中级">中级</el-radio>
                    <el-radio v-model="professionalTitleInfo.lv" label="高级">高级</el-radio>
                    <el-radio v-model="professionalTitleInfo.lv" label="提高h">提高</el-radio>
                </div>
            </div>
            <div>
                <div style="font-size: 18px; margin-bottom: 18px">职称证书（不对外展示）</div>
                <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
                    <el-upload accept="image/*"  class="upload-demo" action="" drag multiple :http-request="($event)=>uploadFrontImg($event,tags)" :on-remove="$event => removeImg($event, tags)">
                        <img v-if="professionalTitleInfo.Cart.length !== 0" width="100%" height="338px" :src="professionalTitleInfo.Cart[0]"/>
                        <i class="el-icon-plus"></i>
                        <div style="font-size: 20px">点击或拖动上传图片（5M以内）</div>
                        <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
                    </el-upload>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <Visible :visible="professionalTitleInfo.isOvert" @selectVisible="e => {professionalTitleInfo.isOvert = e}"></Visible>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="tags === 'certificateCertification'" :model="NVQCartInfo" ref="certificationForm" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 16px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="证书名称"
                          v-model="NVQCartInfo.name"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <div>
                <div style="font-size: 18px; margin-bottom: 18px">职业资格证书（不对外展示）</div>
                <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
                    <el-upload accept="image/*"  class="upload-demo" action="" drag multiple :http-request="($event)=>uploadFrontImg($event,tags)" :on-remove="$event => removeImg($event, tags)">
                        <img v-if="NVQCartInfo.Cart.length !== 0" width="100%" height="338px" :src="NVQCartInfo.Cart[0]"/>
                        <i class="el-icon-plus"></i>
                        <div style="font-size: 20px">点击或拖动上传图片（5M以内）</div>
                        <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
                    </el-upload>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <Visible :visible="NVQCartInfo.isOvert" @selectVisible="e => {NVQCartInfo.isOvert = e}"></Visible>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>

        <el-form v-if="tags === 'certificateOther'" :model="otherCartInfo" ref="otherForm" class="demo-ruleForm">
            <el-form-item prop="name" style="margin-bottom: 16px">
                <el-input type="textarea"
                          resize="none"
                          :rows="2"
                          placeholder="其他类别证书"
                          v-model="otherCartInfo.name"
                          style="margin-bottom: 16px">
                </el-input>
            </el-form-item>
            <div>
                <div style="font-size: 18px; margin-bottom: 18px">证书图片（不对外展示）</div>
                <div style="width: 528px; height: 338px; border-radius: 5px;margin-right: 20px">
                    <el-upload accept="image/*"  class="upload-demo" action="" drag multiple :http-request="($event)=>uploadFrontImg($event,tags)" :on-remove="$event => removeImg($event, tags)">
                        <img v-if="otherCartInfo.Cart.length !== 0" width="100%" height="338px" :src="otherCartInfo.Cart[0]"/>
                        <i class="el-icon-plus"></i>
                        <div style="font-size: 20px">点击或拖动上传图片（5M以内）</div>
                        <div style="color: #999999; font-size: 18px">仅限jpg、png、bmp等图片格式</div>
                    </el-upload>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
                <Visible :visible="otherCartInfo.isOvert" @selectVisible="e => {otherCartInfo.isOvert = e}"></Visible>
                <div>
                    <el-button class="cancel-btn" @click="cancelInfo(tags)">取消</el-button>
                    <el-button class="save-btn" @click="saveInfo(tags)">保存</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import DatePicker from "./DatePicker";
    import Visible from "./Visible";
    export default {
        name: "schoolInfo",
        components: {Visible, DatePicker},

        props: {
            tags: {
                type: String,
                default() {
                    return  '';
                }
            },

            schoolType: {
                type: String,
                default() {
                    return  '';
                }
            },

            schoolEdit: {
                type: Object,
                default() {
                    return  {};
                }
            }
        },

        data() {
            return {
                updateShow: false,

                // 学校
                imgFront: '',
                imgBack: '',
                startTime: '',
                endTime: '',
                schoolInfo: {
                    type: this.schoolType,
                    schoolName: '',
                    major: '',
                    degree: '',
                    remarks: '',
                    isGraduation: '',
                    graduationTime: '',
                    graduationCart: [],
                    isOvert: '1'
                },
                schoolRules: {

                },

                //工作
                workInfo: {
                    company: '',
                    position: '',
                    companyAdress: '',
                    remarks: '',
                    Time: '',
                    isLeave: '',
                    isOvert: '1'
                },
                workRules: {

                },

                // 项目
                projectInfo: {
                    project: '',
                    content: '',
                    isOvert: '1'

                },

                projectRules: {

                },

                // 荣誉
                honorInfo: {
                    name: '',
                    content: '',
                    honorCart: [],
                    isOvert: '1'
                },
                honorRules: {

                },

                // 职称
                professionalTitleInfo: {
                    name: '',
                    lv: '',
                    Cart: [],
                    isOvert: '1'
                },

                // 职业资格
                NVQCartInfo: {
                    name: '',
                    lv: '',
                    Cart: [],
                    isOvert: '1'
                },

                // 其他证书
                otherCartInfo: {
                    name: '',
                    lv: '',
                    Cart: [],
                    isOvert: '1'
                },

            }
        },

        methods: {
            // 上传图片
            uploadFrontImg(params, tag) {
                var that = this;
                var file = params.file;
                const isLt5M = file.size / 1024 / 1024 < 8;
                if (isLt5M) {
                    if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
                        that.utils.upload(file,function(url){
	                        if (!url){
		                        return false;
	                        }
                            if (tag === 'educationFront') {
                                that.imgFront = url
                            }
                            if (tag === 'educationBack') {
                                that.imgBack = url
                            }
                            if (tag === 'honor') {
                                that.honorInfo.honorCart = [url];
                            }
                            if (tag === 'certificateRank') {
                                that.professionalTitleInfo.Cart = [url];
                            }
                            if (tag === 'certificateCertification') {
                                that.NVQCartInfo.Cart = [url];
                            }
                            if (tag === 'certificateOther') {
                                that.otherCartInfo.Cart = [url];
                            }
                        })
                    } else {
                        this.$message.error('只能上传图片类型!')
                    }

                } else {
                    this.$message.error('上传文件大小不能超过 8MB!')
                }
            },

            // 移除文件
            removeImg($event, e) {
                if (e === 'educationFront') {
                    this.imgFront = ''
                }
                if (e === 'educationBack') {
                    this.imgBack = ''
                }
                if (e === 'honor') {
                    this.honorInfo.honorCart = []
                }
                if (e === 'certificateRank') {
                    this.professionalTitleInfo.Cart = [];
                }
                if (e === 'certificateCertification') {
                    this.NVQCartInfo.Cart = [];
                }
                if (e === 'certificateOther') {
                    this.otherCartInfo.Cart = [];
                }
            },

            // 取消
            cancelInfo(e) {
                if (e === 'education') {
                    this.startTime = '';
                    this.endTime = '';
                    this.schoolInfo = {
                        type: this.schoolType,
                        schoolName: '',
                        major: '',
                        degree: '',
                        remarks: '',
                        isGraduation: '',
                        graduationTime: '',
                        graduationCart: [],
                        isOvert: '1'
                    };
                    this.$emit('cancelAddSchool')
                }
                if (e === 'work') {
                    this.workInfo = {
                        company: '',
                        position: '',
                        companyAdress: '',
                        remarks: '',
                        Time: '',
                        isLeave: '',
                        isOvert: '1'
                    };
                    this.$emit('cancelAddWork')
                }
                if (e === 'project') {
                    this.projectInfo = {
                        name: '',
                        content: '',
                        isOvert: '1'
                    };
                    this.$emit('cancelAddProject')
                }
                if (e === 'honor') {
                    this.honorInfo = {
                        name: '',
                        content: '',
                        honorCart: [],
                        isOvert: '1'
                    };
                    this.$emit('cancelAddHonor')
                }
                if (e === 'certificateRank') {
                    this.professionalTitleInfo = {
                        name: '',
                        lv: '',
                        Cart: '',
                        isOvert: '1'
                    };
                    this.$emit('cancelAddProfessionalTitle')
                }
                if (e === 'certificateCertification') {
                    this.NVQCartInfo = {
                        name: '',
                        lv: '',
                        Cart: '',
                        isOvert: '1'
                    };
                    this.$emit('cancelAddNVQCart')
                }
                if (e === 'certificateOther') {
                    this.otherCartInfo = {
                        name: '',
                        lv: '',
                        Cart: '',
                        isOvert: '1'
                    };
                    this.$emit('cancelOtherCart')
                }


            },

            // 获取起始时间
            getStartTime(e) {
                if (e) {
                    this.startTime = e
                }
            },

            // 获取结束时间
            getEndTime(e) {
                if (e) {
                    this.endTime = e
                }
            },

            // 获取入职时间
            getStartWorkTime(e) {
                if (e) {
                    this.workInfo.Time = e
                }
            },

            // 保存表单
            saveInfo(e) {
                if (e === 'education') {
                    this.schoolInfo.graduationCart = [this.imgFront, this.imgBack];
                    this.schoolInfo.graduationTime = this.startTime + '至' + this.endTime;
                    this.$emit('addInfo', this.schoolInfo)
                }
                if (e === 'work') {
                    this.$emit('addWork', this.workInfo)
                }
                if (e === 'project') {
                    this.$emit('addProject', this.projectInfo)
                }
                if (e === 'honor') {
                    this.$emit('addHonor', this.honorInfo)
                }
                if (e === 'certificateRank') {
                    this.$emit('addProfessionalTitle', this.professionalTitleInfo)
                }
                if (e === 'certificateCertification') {
                    this.$emit('addNVQCart', this.NVQCartInfo)
                }
                if (e === 'certificateOther') {
                    this.$emit('addOtherCart', this.otherCartInfo)
                }
            }
        },

        mounted() {
            if (JSON.stringify(this.schoolEdit) !== '{}') {
                if (this.tags === 'education') {
                    this.startTime = '';
                    this.endTime = '';
                    this.imgFront = JSON.parse(JSON.stringify(this.schoolEdit.graduationCart))[0];
                    this.imgBack = JSON.parse(JSON.stringify(this.schoolEdit.graduationCart))[1];
                    this.schoolInfo = JSON.parse(JSON.stringify(this.schoolEdit));
                }
                if (this.tags === 'work') {
                    this.workInfo = JSON.parse(JSON.stringify(this.schoolEdit));
                }
                if (this.tags === 'project') {
                    this.projectInfo = JSON.parse(JSON.stringify(this.schoolEdit));
                }
                if (this.tags === 'honor') {
                    this.honorInfo = JSON.parse(JSON.stringify(this.schoolEdit));
                }
                if (this.tags === 'certificateRank') {
                    this.professionalTitleInfo = JSON.parse(JSON.stringify(this.schoolEdit));
                }
                if (this.tags === 'certificateCertification') {
                    this.NVQCartInfo = JSON.parse(JSON.stringify(this.schoolEdit));
                }
                if (this.tags === 'certificateOther') {
                    this.otherCartInfo = JSON.parse(JSON.stringify(this.schoolEdit));
                }
            }
        }
    }
</script>

<style scoped>
    .school-info {
        width: 100%;
    }

    .open-btn {
        width: 76px;
        height: 38px;
        padding: 2px 0;
        line-height: 6px;
        background-color: #E4E6EB;
        border-radius: 5px;
    }

    .save-btn {
        width: 68px;
        height: 38px;
        color: #fff;
        background-color: #1C7EFF;
        border-radius: 5px;
    }

    .cancel-btn {
        width: 68px;
        height: 38px;
        margin-right: 18px;
        background-color: #E4E6EB;
        border-radius: 5px;
    }

    .resume-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    .upload-demo {
        vertical-align: middle;
    }

    .el-icon-plus {
        color: #2970FF;
        font-size: 44px;
        font-weight: 600;
        text-shadow: 0 3px 6px rgba(0,0,0,0.16);
        margin: 100px 0 14px 0;
    }

    /deep/ .el-upload-dragger {
        width: 516px;
        height: 338px;
        background-color: #FBFBFB;
    }

    /deep/ .el-checkbox__inner {
        width: 18px;
        height: 18px;
        border: #1C7EFF 2px solid;
    }

    /deep/ .el-checkbox__inner::after {
        left: 6px;
        height: 9px;
        width: 4px;
    }

    /deep/ .el-upload-dragger {
        width: 500px;
        height: 300px;
        background-color: #FBFBFB;
    }
</style>
