<template>
  <div class="professional">
    <div style="margin-bottom: 50px">
      <div style="display: flex; justify-content: space-between">
        <div class="resume-title">
          工作经历
        </div>
      </div>
      <div v-if="workShow === false" class="hand op school-add" @click="user.majorAuthStatus === 1 ? utils.msg('审核中，无法编辑！') : workShow = true ">
        <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
        <span>添加工作经历</span>
      </div>
      <div v-if="workShow === true" style="margin-bottom: 40px">

      </div>
      <div v-for="(item, index) in majorInfo.workInfo" :key="new Date().getTime()">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "School",
  components: {},
  data() {
    return {
      workShow: false
    }
  },

  props: {
    majorInfo: {
      type: Object,
      default() {
        return []
      }
    }
  },

  computed: {
    utils() {
      return utils
    },
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
    this.getMajor()
  },

  methods: {
    // 获取专业认证信息
    getMajor() {
      var that = this;
      that.highSchool = false;
      that.$emit('changeMajor')
    },

    // 点击添加学校
    addSchool(e) {
      if (this.user.majorAuthStatus == 1) {
        this.utils.msg('审核中，无法编辑！')
      } else {
        this.highSchool = e;
      }
    },
    // 点击取消添加学校
    cancelAddSchool() {
      this.highSchool = '';
    },
  },
}
</script>

<style scoped>
.resume-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
}

.school-add {
  color: #1C7EFF;
  font-size: 18px;
  width: 16%;
  margin-bottom: 20px;
}

</style>
