<template>
  <div class="professional">
    <div>
      <el-collapse accordion v-model="activeNames">
        <el-collapse-item v-for="(item, index) in schoolTypes" :key="index" :name="item">
          <template slot="title">
            <span style="font-size: 18px ;font-weight: 600">{{item}}</span>
            <div class="hand op school-add" @click.stop="" @click="addSchool(item)" style="margin: 20px; width: 30px;">
              <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
            </div>
          </template>
          <div v-if="highSchool === item" style="margin-bottom: 40px">
            <SchoolFrom :type="item" @reMajor="getMajor"></SchoolFrom>
          </div>
          <div>
            <div v-for="(value, key) in majorInfo" :key="new Date().getTime() + key" style="margin-top: 20px">
              <SchoolData @reMajor="getMajor" v-if="value.type === item" :schoolData="value"></SchoolData>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
<!--      <div style="margin-bottom: 50px" v-for="(item, index) in schoolTypes" :key="index">-->
<!--        <div style="display: flex; justify-content: space-between">-->
<!--          <div class="resume-title">-->
<!--            {{item}}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <div class="hand op school-add" @click="addSchool(item)">-->
<!--            <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>-->
<!--            <span>添加学校</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-if="highSchool === item" style="margin-bottom: 40px">-->
<!--          <SchoolFrom :type="item" @reMajor="getMajor"></SchoolFrom>-->
<!--        </div>-->
<!--        <div>-->
<!--          <div v-for="(value, key) in majorInfo" :key="new Date().getTime() + key" style="margin-top: 20px">-->
<!--            <SchoolData @reMajor="getMajor" v-if="value.type === item" :schoolData="value"></SchoolData>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils";
import SchoolFrom from "@/pages/circle/components/Authentication/SchoolFrom.vue";
import SchoolData from "@/pages/circle/components/Authentication/SchoolData.vue";
export default {
  name: "School",
  components: {SchoolData, SchoolFrom},
  data() {
    return {
      schoolTypes: ['本科以下学历', '本科', '研究生', '博士', '其他教育经历'],
      highSchool: '',
      activeNames: '',
    }
  },

  props: {
    majorInfo: {
      type: Array,
      default() {
        return []
      }
    }
  },

  computed: {
    utils() {
      return utils
    },
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },

  mounted() {
    this.getMajor()
  },

  methods: {
    // 获取专业认证信息
    getMajor(e) {
      var that = this;
      if (e === 'schoolInfo') {
        that.highSchool = '';
      }
      that.$emit('changeMajor')
    },

    // 点击添加学校
    addSchool(e) {
      this.activeNames = e
      if (this.user.majorAuthStatus == 1) {
        this.utils.msg('审核中，无法编辑！')
      } else {
        this.highSchool = e;
      }
    },
    // 点击取消添加学校
    cancelAddSchool() {
      this.highSchool = '';
    },
  },
}
</script>

<style scoped>
.resume-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
}

.school-add {
  color: #1C7EFF;
  font-size: 18px;
  width: 16%;
  margin-bottom: 20px;
}

</style>
