<template>
    <div class="school-data">
        <div class="content" v-if="updateShow === false">
            <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
                <div style="width: 72%">
                    <span class="content-title">学校：</span>
                    <span>{{schoolInfo.schoolName}}</span>
                </div>
                <div style="display: flex">
                    <div v-if="schoolInfo.status === 0" class="hand op" style="margin-right: 10px" @click="updateShow = true">
                        <img src="../../../../assets/imgs/circle/editorC.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div v-if="schoolInfo.status === 0" class="hand op" style="margin-right: 10px" @click="deleteInfo">
                        <img src="../../../../assets/imgs/circle/trash.png" style="vertical-align: middle; margin-right: 4px"/>
                    </div>
                    <div style="width: 88px;text-align: center; line-height: 20px; padding: 4px 0; border-radius: 20px;border: 1px solid #BDBFC1;">
                      <el-checkbox v-model="schoolInfo.isShow" @change="changeSchoolShow" :true-label="1" :false-label="0">展示</el-checkbox>
                    </div>
                    <div class="replay-btn hand op" v-if="schoolInfo.status === 0" @click="submitMajor" style="background-color: #2970FF">
                      申请专业认证
                    </div>
                    <div class="replay-btn" v-if="schoolInfo.status === 1" style="background-color: #FF9800">
                      正在进行认证
                    </div>
                    <div class="replay-btn hand op" v-if="schoolInfo.status === 2" style="background-color: #5BC130">
                      认证通过
                    </div>
                </div>
            </div>
            <div class="info">
                <span class="content-title">专业：</span>
                <span>{{schoolInfo.major}}</span>
            </div>
            <div class="info">
                <span class="content-title">学位：</span>
                <span>{{schoolInfo.degree}}</span>
            </div>
            <div class="info">
                <span class="content-title">说明：</span>
                <span>{{schoolInfo.explain}}</span>
            </div>
            <div class="info">
                <span class="content-title">毕业时间：</span>
                <span>{{schoolInfo.time[0]}} 至 {{schoolInfo.time[1]}}</span>
            </div>
            <div style="display: flex" v-if="schoolInfo.images">
                <div v-if="schoolInfo.images.length > 0">
                    <img v-for="(item, index) in schoolInfo.images" :src="item.url" style="width: 428px; height: 300px; border-radius: 5px;margin: 0 20px 10px 0;">
                </div>
            </div>
        </div>
        <SchoolFrom :type="schoolData.type" v-if="updateShow === true" :schoolData="schoolData" @reMajor="getMajor"></SchoolFrom>
    </div>
</template>

<script>
import SchoolFrom from "./SchoolFrom";
export default {
    name: "schoolData",
    components: {SchoolFrom},
    props: {
        schoolData: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    computed: {
      user() {
        return this.$store.state.user;
      },
      is_login(){
        return this.$store.state.is_login
      },
      router(){
        return this.$store.state.router
      }
    },

    data() {
        return {
            updateShow: false,
            schoolInfo: this.schoolData,
            showApply: false,
        }
    },

    mounted() {
    },

    methods: {
        // 提交教育认证
        submitMajor() {
          let that = this;
          if (that.user.authenticationStatus > 0) {
            that.utils.confirm('请确认提交审核内容，审核通过后将无法修改', function () {
              that.newApi.submitEducationAudit({type: that.schoolInfo.type}).then(res => {
                if (res.isSuccess === 1) {
                  that.$emit('reMajor', 'schoolInfo')
                }
              })
            })
          } else {
            that.utils.msg('未通过实名认证，无法申请')
          }

        },

        // 更新展示
        changeSchoolShow() {
          this.newApi.setSchoolInfo({'schoolInfo': JSON.stringify(this.schoolInfo)}).then(res => {
            if (res.isSuccess === 1) {
              this.$emit('reMajor', 'schoolInfo')
            }
          })
        },

        // 更新数据
        getMajor() {
            this.updateShow = false;
            this.$emit('reMajor', 'schoolInfo')
        },

        // 删除数据
        deleteInfo(){
            let that = this;
            that.utils.confirm('确定删除此学历信息？', function () {
                that.newApi.deleteSchoolInfo({type: that.schoolData.type}).then(res => {
                    if (res.isSuccess === 1) {
                        that.$emit('reMajor')
                    }
                })
            })

        },
    },
}
</script>

<style scoped>
.school-data {
  margin-bottom: 10px;
}

.school-data .el-checkbox{
  margin-right:0;
}

.content {
    font-size: 18px;
    padding: 20px;
    box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #BDBFC1;
}

.save-btn {
  width: 118px;
  height: 38px;
  color: #fff;
  background-color: #1C7EFF;
  border-radius: 5px;
}

.cancel-btn {
  width: 118px;
  height: 38px;
  margin-right: 18px;
  background-color: #E4E6EB;
  border-radius: 5px;
}

.replay-btn {
  width: 120px;
  text-align: center;
  font-size: 14px;
  padding: 6px 0;
  border-radius: 20px;
  margin-left: 10px;
  color: #FFFFFF;
}

.info {
    margin-bottom: 20px;
}

.content-title {
    color: #999999;
}

.content-title-img {
   font-weight: 600;
}

.upload-demo {
  vertical-align: middle;
}

.el-icon-plus {
  color: #2970FF;
  font-size: 44px;
  font-weight: 600;
  text-shadow: 0 3px 6px rgba(0,0,0,0.16);
  margin: 100px 0 14px 0;
}

/deep/ .el-upload-dragger {
  width: 516px;
  height: 338px;
  background-color: #FBFBFB;
}

/*公共*/
.hand{
    cursor:pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.op:active{
    opacity:0.6;
    -webkit-transition:all .1s;transition:all .1s;opacity:.6;
}

.op{
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .5s;
    transition: all .5s;
}
</style>
